<template>
    <div class="email-campaign">
        <!-- email-next -->
        <div class="email-next">
            <div class="list-btns">
                <ul>
                    <li><a v-on:click="step=1" class="step1" v-bind:class="step == 1 ? 'active':''" href="#">Detail</a></li>
                    <li><a v-on:click="step=2" class="step2" v-bind:class="step == 2 ? 'active':''" href="#">Template</a></li>
                    <li><a v-on:click="step=3" class="step3" v-bind:class="step == 3 ? 'active':''" href="#">Customize Template</a></li>
                    <li><a v-on:click="step=4" class="step4" v-bind:class="step == 4 ? 'active':''" href="#">Preview</a></li>
                    <li><a v-on:click="step=5" class="step5" v-bind:class="step == 5 ? 'active':''" href="#">Recipients</a></li>
                    <span class="bar"></span>
                </ul>
            </div>
        </div>
        <!-- email-next -->
        <!-- email-step1 -->
        <div class="email-step1" v-show="step == 1">
            <div class="step1-area">
                <div class="email-deatils">
                    <h3>Email Details</h3>
                    <form action="">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="campaignName">Campaign Name *</label>
                                <input v-model="email_detail_.campaign_name" type="text" class="form-control" id="campaignName" placeholder="Enter campaign name" />
                            </div>
                            <div class="form-group sub-emoji col-md-6">
                                <label for="Subject">Subject *</label>
                                <input v-model="email_detail_.subject" type="text" class="form-control" id="Subject" placeholder="Subject here" />
                                <a href="#"><img class="img-fluid" src="@/assets/images/icons/emoji.svg" alt="" /></a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="fromName">From Name *</label>
                                <input v-model="email_detail_.from_name" type="text" class="form-control" id="fromName" placeholder="Name here" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="fromEmail">From Email *</label>
                                <input v-model="email_detail_.from_email" type="text" class="form-control" id="fromEmail" placeholder="Email here" />
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <label class="containerw">
                                use this as my reply-to address
                                <input type="checkbox" v-model="email_.same_reply_to" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="row" v-if="!email_.same_reply_to">
                            <div class="form-group col-md-6">
                                <label for="replyName">ReplyTo Name *</label>
                                <input v-model="email_.replyTo_name" type="text" class="form-control" id="replyName" placeholder="Name here" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="replyEmail">ReplyTo Email *</label>
                                <input v-model="email_.replyTo_email" type="text" class="form-control" id="replyEmail" placeholder="Email here" />
                            </div>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
        <!-- email-step2 -->
        <div class="email-step2" v-show="step == 2">
            <div class="step1-area">
                <div class="email-deatils">
                    <h3>Choose Template</h3>
                    <div class="row">
                        <div class="col-md-3 p-2"><img src="@/assets/images/email_templates/template.png" class="img-thumbnail img-fluid" alt="Cinque Terre" /></div>
                        <div class="col-md-3 p-2"><img src="@/assets/images/email_templates/template.png" class="img-thumbnail img-fluid" alt="Cinque Terre" /></div>
                        <div class="col-md-3 p-2"><img src="@/assets/images/email_templates/template.png" class="img-thumbnail img-fluid" alt="Cinque Terre" /></div>
                        <div class="col-md-3 p-2"><img src="@/assets/images/email_templates/template.png" class="img-thumbnail img-fluid" alt="Cinque Terre" /></div>
                        <div class="col-md-3 p-2"><img src="@/assets/images/email_templates/template.png" class="img-thumbnail img-fluid" alt="Cinque Terre" /></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- email-step3 -->
        <div class="email-step3" v-bind:class="step == 3 ? 'active':''">
            <div class="step1-area">
                <div class="email-deatils">
                    <h3>Customizing a Campaign Template</h3>
                    <div class="d-flex">
                        <EmailEditor ref="emailEditor" style="min-height: 500px;" v-on:load="editorLoaded" />
                    </div>
                </div>
            </div>
        </div>
        <!-- email-step4 -->
        <!-- <div class="email-step4" v-bind:class="step == 4 ? 'active':''">
            <div class="step1-area">
                <div class="email-deatils">
                    <h3>Preview Template</h3>
                    <iframe :srcdoc="email_.template"></iframe>
                </div>
            </div>
        </div> -->
        <!-- email-step5 -->
        <!-- <div class="email-step5" v-bind:class="step == 5 ? 'active':''">
            <div class="step1-area">
                <div class="email-deatils">
                    <h3>Recipient List</h3>
                    <form action="">
                        <div class="list-tab">
                            <div class="form-row">
                                <div class="form-group col-sm-6 col-md-6 col-lg-8">
                                    <multi-select :searchable="true" id="campaigns_lists" textColumn="ecl_name" placeholder="Choose campaigns list" v-model="email_.campaign_list" :options="campaignsList" label="Select lists"></multi-select>
                                    <div class="validate-error-message" v-if="$vd.email_.campaign_list.$hasError === true">{{$vd.email_.$errors.campaign_list[0]}}</div>
                                    
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="send-list">
                                    <label for="">Sent to:</label>
                                    <ul class="row">
                                        <li class="col-md-4" v-for="ecl in email_.campaign_list" :key="ecl.ecl_id"><img class="img-fluid" src="@/assets/images/icons/check.svg" alt="" /> {{ecl.ecl_name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="con-company currnt send-tab">
                                    Send now
                                    <input type="radio" name="radio" v-bind:value="false" v-model="email_.schedule" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="form-group col-md-6 col-lg-4">
                                <label class="con-company sche-tab">
                                    Schedule for Later
                                    <input type="radio" name="radio" v-bind:value="true" v-model="email_.schedule" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="validate-error-message" v-if="$vd.email_.schedule.$hasError === true">{{$vd.email_.$errors.schedule[0]}}</div>
                        </div>

                        <div class="send-now" v-if="!email_.schedule">
                            <div class="send-info">
                                <p>Your email will be sent immediately.</p>
                            </div>
                        </div>
                        <div class="sche-later" v-if="email_.schedule">
                            <div class="send-info">
                                <p>Schedule a future time and date to publish your email.</p>
                                <h3>When do you want to send it out?</h3>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-5 col-lg-4">
                                    <date-range-picker :locale-data="{ format: 'mmmm dd, yyyy' }" v-model="email_.schedule_date" :single-date-picker="true" :show-dropdowns="true" :auto-apply="true" :ranges="false"></date-range-picker>
                                </div>
                                <div class="form-group col-md-5 col-lg-4">
                                    <div class="form-row">
                                        <div class="form-group">
                                            <vue-timepicker v-model="email_.time" format="hh:mm A" :minute-interval="5" close-on-complete placeholder="hh:mm a"></vue-timepicker>
                                        </div>
                                        <p class="time-zoon mr-2">(UTC+03:00)</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="timezoon"></div>
                    </form>
                </div>
            </div>
        </div> -->
        <div class="row mt-4">
            <div class="col-12">
                <a class="add-btn float-left pr-5 pl-5" v-bind:class="step == 1 ? 'd-none' : ''" href="#" v-on:click="step == 3 ? step = step-2:step = step-1">Back</a>
                <a class="rem-btn float-right pr-5 pl-5" href="#" v-on:click="nextStep">
                    <span v-if="step < 5">next</span><span v-if="step == 5 && !btnLoader">Submit</span><span v-if="btnLoader"><span class="spinner-grow spinner-grow-sm"></span> Loading...</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { EmailEditor } from "vue-email-editor";
    import axios from "axios";
    //import MultiSelect from "../helpers/MultiSelect";
    //import VueDaval from "vue-daval";
    //import DateRangePicker from "vue2-daterange-picker";
    //import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
    //import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
    import moment from "moment";
    import $ from "jquery";
    export default {
        name: "AddCampaigns",
        //mixins: [VueDaval],
        components: {
            EmailEditor,
            //MultiSelect,
            //DateRangePicker,
            //VueTimepicker,
        },
        // vdRules: {
        //     email_detail_: {
        //         campaign_name: { required: true },
        //         subject: { required: true },
        //         from_name: { required: true },
        //         from_email: { required: true, type: "email" },
        //     },
        //     email_: {
        //         campaign_list: { required: true },
        //         schedule: { required: true },
        //     },
        // },
        created() {
            this.getCampaignsList();
        },
        data() {
            return {
                step: 1,
                email_detail_: { campaign_name: "", subject: "", from_name: "", from_email: "" },
                email_: { same_reply_to: true, schedule: false, schedule_date: {}, time: "", date: "", campaign_list: "" },
                templates: [],
                campaignsList: [],
                btnLoader: false,
            };
        },
        methods: {
            nextStep() {
                
                if (this.step == 1) {
                    //validate Form
                    this.step = this.step + 1;
                    // if (this.templates.length) {
                    //     this.step = this.step + 1;
                    // } else {
                    //     this.step = this.step + 2;
                    // }

                } else if (this.step == 2) {
                    this.step = this.step + 1;
                } else if (this.step == 3) {
                    this.$refs.emailEditor.editor.saveDesign((design) => {
                        this.email_.design = design;
                    });
                    this.$refs.emailEditor.editor.exportHtml((data) => {
                        this.email_.template = data.html;
                        this.step = this.step + 1;
                    });
                } else if (this.step == 4) {
                    this.step = this.step + 1;
                } else if (this.step == 5) {
                    this.btnLoader = true;
                    this.$vd.email_
                        .$validate()
                        .then(() => {
                            $.extend(this.email_, this.email_detail_);
                            this.email_.date = moment(new Date(this.email_.schedule_date.startDate)).format("YYYY-MM-DD");
                            //console.log(this.email_);
                            axios
                                .post("add_new_campaign",this.email_)
                                .then((res) => {
                                    if (res.data.success) {
                                        this.btnLoader = false;
                                        this.$router.push("/campaigns");
                                        console.log(res.data.success);
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                        .catch(() => {
                            console.log(this.$vd.email_.$errors);
                        });
                }
            },
            getCampaignsList() {
                axios
                    .get("campaigns_list")
                    .then((res) => {
                        if (res.data.success) {
                            this.campaignsList = res.data.success;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            editorLoaded() {
                if ($("iframe").contents().find("a.blockbuilder-branding")) {
                    //alert("find")
                    $("iframe").contents().find(".blockbuilder-branding").remove();
                }
                //this.$refs.emailEditor.editor.loadDesign({});
            },
            saveDesign() {
                this.$refs.emailEditor.editor.saveDesign((design) => {
                    console.log("saveDesign", design);
                });
            },
            exportHtml() {
                this.$refs.emailEditor.editor.exportHtml((data) => {
                    console.log("exportHtml", data);
                });
            },
        },
    };
</script>
<style scoped>
    .unlayer-editor {
        flex: 1;
        display: flex;
    }
    iframe {
        width: 100%;
        height: 500px;
    }
</style>
<style>
    .blockbuilder-branding {
        display: none !important;
    }
</style>
